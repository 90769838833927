.sold-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.sold-cards-table {
    width: 100%;
    max-width: 1000px;
    border-collapse: collapse;
    margin-bottom: 20px;
}

.sold-cards-table th,
.sold-cards-table td {
    border: 1px solid #ddd;
    padding: 8px;
}

.sold-cards-table th {
    background-color: #f2f2f2;
    text-align: center;
}

.sold-cards-table td {
    text-align: center;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    padding: 5px 10px;
    margin: 0 2px;
    cursor: pointer;
}

.pagination button.active {
    background-color: #4caf50;
    color: white;
}

@media screen and (max-width: 600px) {
    .sold-cards-table {
        font-size: 12px;
    }

    .sold-cards-table th,
    .sold-cards-table td {
        padding: 4px;
    }

    .pagination button {
        padding: 3px 5px;
        margin: 0 1px;
    }
}