.broadcast-email-container {
    max-width: 600px;
    margin: auto;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #fff;
    justify-self: center;
    align-self: center;
}

.broadcast-email-container h2 {
    text-align: center;
}

.broadcast-email-container label {
    display: block;
    margin-top: 10px;
}

.broadcast-email-container input,
.broadcast-email-container textarea {
    width: 100%;
    padding: 10px;
    margin-top: 5px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.broadcast-email-container button {
    margin-top: 20px;
    padding: 10px 20px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.broadcast-email-container button:hover {
    background-color: #0056b3;
}

.broadcast-email-container p {
    margin-top: 10px;
    color: green;
}