/* src/components/SoldCardsCount.css */
.sold-cards-container {
    padding: 20px;
    background-color: #f4f4f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    max-width: 1200px;
    /* Increased max-width */
    margin: auto;
    font-family: Arial, sans-serif;
    color: #333;
    text-align: center;
}

.sold-cards-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #0056b3;
}

.count-section {
    margin-bottom: 20px;
}

.count-section h3 {
    margin-bottom: 10px;
    color: #333;
    border-bottom: 1px solid #ddd;
    padding-bottom: 5px;
}

.count-section div {
    margin: 5px 0;
    padding: 16px;
    /* Increased padding */
    background-color: #fff;
    border-radius: 5px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    cursor: pointer;
    transition: transform 0.2s;
    display: block;
    /* Changed to block for column layout */
    width: 100%;
    /* Full width for columns */
}

.count-section div:hover {
    transform: scale(1.05);
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    font-size: 24px;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {
    .sold-cards-container {
        padding: 10px;
    }

    .sold-cards-container h2 {
        font-size: 1.5em;
    }

    .count-section h3 {
        font-size: 1.2em;
    }

    .count-section div {
        font-size: 0.9em;
        width: 100%;
        /* Full width for mobile */
    }
}