.product-grid {
    display: flex;
    /* flex-wrap: wrap; */
    justify-content: center;
    flex-direction: 'row';

    gap: 16px;
    /* Space between product cards */
    padding: 16px;
    /* Padding around the grid */
}

@media (max-width: 768px) {
    .product-grid {
        flex-direction: column;
        /* Change flex direction to column for mobile view */
        align-items: center;
    }
}