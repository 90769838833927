.card-payment {
    background-color: #4caf50;
}
.payment-button {
    display: flex;
    align-items: center;
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
}