.about-us-section {
    margin-top: 50px;
    text-align: left;
    color: white;
    padding: 20px;
    background-color: black;
}

.about-us-content {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.about-us-text {
    flex: 1;
    padding: 10px;
    font-size: 14px;
}

.about-us-images {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.about-image {
    width: 100%;
    border-radius: 10px;
}

@media (min-width: 768px) {
    .about-us-content {
        flex-direction: row;
    }

    .about-us-text {
        font-size: 16px;
    }
}