.register-container {
    display: flex;
    justify-content: center;
    height: 100vh;
}

.register-form {
    display: flex;
    flex-direction: column;
    width: 300px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.my-1 {
    margin-bottom: 10px;
}

.register-button {
    background-color: #000;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
}

.register-button:disabled {
    background-color: gray;
    cursor: not-allowed;
}

.register-button:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
}