/* src/components/SoldCardsTable.css */
.table-container {
    width: 100%;
    overflow-x: auto;
    padding: 20px;
}

.sold-cards-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.sold-cards-table th,
.sold-cards-table td {
    padding: 10px;
    border: 1px solid #ddd;
    text-align: left;
}

.sold-cards-table th {
    background-color: #f2f2f2;
}

.loader {
    text-align: center;
    font-size: 18px;
    padding: 20px;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.pagination button {
    margin: 0 5px;
    padding: 5px 10px;
    border: none;
    background-color: #0056b3;
    color: white;
    cursor: pointer;
    border-radius: 3px;
    transition: background-color 0.3s;
}

.pagination button.active {
    background-color: #004494;
}

.pagination button:hover {
    background-color: #003366;
}

/* Media Queries for Mobile Responsiveness */
@media (max-width: 768px) {

    .sold-cards-table th,
    .sold-cards-table td {
        padding: 8px;
        font-size: 12px;
    }

    .pagination button {
        padding: 3px 6px;
        font-size: 12px;
    }
}