.product-list {
    width: 100%;
    padding: 20px;
    text-align: center;
    background-color: white;
}

.product-items {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style: none;
    padding: 0;
    background-color: white;
}

.product-item {
    width: 200px;
    margin: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
    cursor: pointer;
    transition: transform 0.2s;
    background-color: white;
    position: relative;
}

.product-item.not-available {
    opacity: 0.5;
    pointer-events: none;
}

.product-item:hover {
    transform: scale(1.05);
}

.product-info {
    margin-top: 10px;
}

.product-name {
    font-size: 1em;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    color: black;
    text-overflow: ellipsis;
}

.product-price {
    color: #22171b;
    font-size: large;
}

.image-container {
    position: relative;
}

.image-container .grayscale {
    filter: grayscale(100%);
}

.out-of-stock-text {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: black;
    color: white;
    padding: 5px 10px;
    border-radius: 5px;
    font-weight: bold;
}