body {
    font-family: 'Arial', sans-serif;
    background-color: #f5f7fa;
    margin: 0;
    padding: 0;
}

.forgot-password-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 40px;
    margin-top: -50px;
    /* Move the form up */
}

h2 {
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
}

.forgot-password-form {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
}

.forgot-password-form label {
    margin-bottom: 16px;
    font-weight: bold;
    color: #333;
}

.forgot-password-form input {
    margin-bottom: 24px;
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s;
}

.forgot-password-form input:focus {
    border-color: #1976d2;
    outline: none;
}

.forgot-password-form button {
    padding: 12px;
    font-size: 16px;
    background-color: #1976d2;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
}

.forgot-password-form button:disabled {
    background-color: #ccc;
}

.forgot-password-form button:not(:disabled):hover {
    background-color: #155a9a;
}

.message {
    margin-top: 20px;
    font-size: 14px;
    color: green;
}