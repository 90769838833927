.bank-account-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px 0;
    max-width: 250px;
    max-height:100px
    /* Shrink the card size */
}

.bank-account-card img {
    width: 40px;
    max-width: 30px;
    margin-bottom: 10px;
}

.account-container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    padding-top: 0;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.content {
    flex: 1;
    padding: 20px;
}

.header {
    display: flex;
    flex-direction: column;
    /* Change direction for mobile */
    justify-content: space-between;
    align-items: center;
}

.welcome-message {
    flex: 1;
}

.button-group {
    display: flex;
    flex-direction: column;
    /* Change direction for mobile */
    gap: 10px;
}

@media (min-width: 768px) {
    .header {
        flex-direction: row;
        /* Change direction back for larger screens */
    }

    .button-group {
        flex-direction: row;
        /* Change direction back for larger screens */
    }
}

.MuiAppBar-root {
    background-color: #0e1114;
}

.MuiToolbar-root {
    display: flex;
    justify-content: space-between;
}

.title {
    margin-left: 20px;
}

.MuiDrawer-paper {
    width: 250px;
}

.MuiListItemText-root {
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    text-align: center;
    margin: 10px 0;
}