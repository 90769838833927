.testimonials-section {
    padding: 3rem 1rem;
    background-color: #f0f2f5;
}

.container {
    max-width: 100%;
    margin: 0 auto;
    padding: 0 1rem;
}

.text-center {
    text-align: center;
}

.text-lg {
    font-size: 1.125rem;
}

.text-3xl {
    font-size: 1.875rem;
}

.text-4xl {
    font-size: 2.25rem;
}

.text-5xl {
    font-size: 3rem;
}

.font-bold {
    font-weight: bold;
}

.font-medium {
    font-weight: 500;
}

.text-gray-600 {
    color: #718096;
}

.text-gray-900 {
    color: #1a202c;
}

.mt-4 {
    margin-top: 1rem;
}

.mt-8 {
    margin-top: 2rem;
}

.mt-10 {
    margin-top: 2.5rem;
}

.md\:mt-16 {
    margin-top: 4rem;
}

.md\:mt-24 {
    margin-top: 6rem;
}

.relative {
    position: relative;
}

.absolute {
    position: absolute;
}

.-inset-x-1 {
    left: -0.25rem;
    right: -0.25rem;
}

.inset-y-16 {
    top: 4rem;
    bottom: 4rem;
}

.md\:-inset-x-2 {
    left: -0.5rem;
    right: -0.5rem
}