.transaction-history {
    padding: 20px;
    max-width: 100%;
    margin: 0 auto;
    overflow-x: auto;
    /* Add horizontal scroll for mobile responsiveness */
    padding-bottom: 100px;
    /* Add padding to the bottom */
}

.transaction-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.card-type-counters {
    display: flex;
    justify-content: space-around;
    margin-bottom: 20px;
}

.card-type-counter {
    text-align: center;
}

.card-type {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.counter {
    font-size: 36px;
    font-weight: bold;
    color: #2196f3;
}

.total-cards,
.total-price {
    font-size: 18px;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
}

.table-container {
    overflow-x: auto;
    overflow-y: auto;
    max-height: 400px;
    /* Adjust height as needed */
}

.transaction-table {
    width: 100%;
    border-collapse: collapse;
}

.transaction-table thead {
    background-color: black;
}

.transaction-table th,
.transaction-table td {
    padding: 10px;
    text-align: left;
    border: 1px solid #e0e0e0;
}

.transaction-table th {
    color: white;
    font-weight: bold;
}

.transaction-table tbody tr:nth-child(even) {
    background-color: #f2f2f2;
}

.transaction-table tbody tr:hover {
    background-color: #e0e0e0;
}

.loading-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

@media (max-width: 768px) {

    .transaction-table th,
    .transaction-table td {
        padding: 8px;
    }

    .transaction-title {
        font-size: 20px;
    }

    .card-type-counters {
        flex-direction: column;
        align-items: center;
    }

    .card-type-counter {
        margin-bottom: 10px;
    }

    .counter {
        font-size: 28px;
    }

    .total-cards,
    .total-price {
        font-size: 16px;
    }
}