.product-card {
    margin: 20px;
    width: 200px;
    transition: opacity 0.5s ease-in-out;
    opacity: 0;
    animation: fadeIn 1s forwards;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

.image-container {
    position: relative;
}

.out-of-stock {
    position: relative;
}

.ribbon {
    position: absolute;
    top: 100px;
    left: -50px;
    background-color: red;
    color: white;
    padding: 5px 10px;
    transform: rotate(-50deg);
    transform-origin: left top;
    width: 200px;
    z-index: 1;
}

.grayscale {
    filter: grayscale(100%);
}

.quantity-selector {
    display: flex;
    align-items: center;
    justify-content: center;
}

.card-loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
}