body {
    margin: 0;
    font-family: "Helvetica", sans-serif;
    background-color: #f4f4f4;
}

.maintenance-container {
    text-align: center;
    padding: 150px;
}

h1 {
    font-size: 50px;
}

p {
    font-size: 22px;
}