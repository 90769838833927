.transaction-container {
    padding: 20px;
    max-width: 1000px;
    margin: 0 auto;
}

.transaction-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
}

.transaction-actions {
    margin-top: 20px;
    display: flex;
    justify-content: space-between;
}

.table-container {
    max-height: 500px;
    /* Set a max height for the table container */
    overflow-y: auto;
    /* Enable vertical scrolling */
}

.table-container::-webkit-scrollbar {
    width: 12px;
}

.table-container::-webkit-scrollbar-track {
    background: #f1f1f1;
}

.table-container::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 10px;
}

.table-container::-webkit-scrollbar-thumb:hover {
    background: #555;
}