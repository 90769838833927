.login-container {
    display: flex;
    justify-content: center;
    height: 100vh;
}

.login-form {
    display: flex;
    flex-direction: column;
    width: 300px;
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
}

.my-1 {
    margin-bottom: 10px;
}

.login-button {
    background-color: #000;
    color: #fff;
    padding: 10px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    margin-top: 10px;
}

.login-button:disabled {
    background-color: gray;
    cursor: not-allowed;
}

.login-button:hover {
    background-color: #fff;
    color: #000;
    border: 1px solid #000;
}

.forgot-password-link {
    margin-top: 10px;
}

.forgot-password-link a {
    color: #000;
    text-decoration: none;
    font-size: 0.9em;
    transition: color 0.3s ease;
}

.forgot-password-link a:hover {
    color: #007bff;
}