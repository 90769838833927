.fund-wallet {
    padding: 20px;
    max-width: 800px;
    margin: 0 auto;
}

.fund-wallet h2 {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
}

.fund-wallet p {
    font-size: 16px;
    margin-bottom: 20px;
}

.bank-cards {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
}

.bank-card {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 20px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    width: calc(33.333% - 20px);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    background-color: #fff;
}

.bank-logo {
    width: 50px;
    height: 50px;
    margin-right: 20px;
}

.bank-info {
    display: flex;
    flex-direction: column;
}

.bank-name {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 5px;
}

.account-name,
.account-number {
    font-size: 16px;
    color: #757575;
}

@media (max-width: 768px) {
    .bank-card {
        width: calc(50% - 20px);
    }
}

@media (max-width: 480px) {
    .bank-card {
        width: 100%;
    }
}