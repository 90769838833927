@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@300;400;500;700&display=swap');

.home-container {
    position: relative;
    width: 100%;
    min-height: 250vh;
    overflow-y: auto;
    padding: 20px;
    /* Added padding */
}

.vanta-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
}

.home-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white;
    text-align: center;
    padding: 20px;
    /* Added padding */
}

.home-title {
    font-family: 'Oswald', sans-serif;
    font-size: 3em;
    margin-bottom: 20px;
    color:black
}

.intro-text p {
    font-size: 1.2em;
    margin-bottom: 30px;
    color: black;
    font-family:sans-serif;
}

.auth-buttons {
     display: flex;
    flex-direction: 'column';
    gap: 10px;
    margin-top: 20px;
}

.auth-button {
    background-color: transparent;
    border: 2px solid black;
    color: black;
    font-size: 1em;
    min-width: 150px;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    text-align: center;
    transition: all 0.3s ease;
}

.auth-button:hover {
    background-color: black;
    color: white;
}

.footer {
    margin-top: 50px;
    text-align: center;
    font-size: 0.9em;
    color: #ddd;
}