.product-description {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
}

.image-container {
    margin-bottom: 20px;
}

.description-container {
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    width: 100%;
    max-width: 600px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.description-content {
    background-color: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.quantity-selector {
    display: flex;
    align-items: center;
    margin-top: 10px;
}

.quantity-button {
    background-color: #e0e0e0;
    color: #333;
    padding: 5px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin: 0 10px;
}

.payment-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-top: 10px;
}

.payment-buttons {
    display: flex;
    gap: 10px;
}

.payment-button {
    display: flex;
    align-items: center;
    padding: 10px;
    border: none;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
}

.card-payment {
    background-color: #4caf50;
}

.bank-transfer {
    background-color: #2196f3;
}

.note {
    color: #d32f2f;
    margin-top: 10px;
}

@media (min-width: 768px) {
    .product-description {
        flex-direction: row;
        justify-content: center;
        align-items: flex-start;
    }

    .image-container {
        margin-right: 20px;
    }

    .description-container {
        max-width: 800px;
    }

    .payment-buttons {
        flex-direction: row;
    }
}

@media (max-width: 600px) {
    .description-container {
        width: 100%;
        padding: 10px;
    }

    .quantity-button {
        padding: 5px;
    }

    .payment-button {
        padding: 8px;
    }

    .payment-buttons {
        flex-direction: column;
    }
}