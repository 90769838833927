.reset-password-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50vh;
    /* width: 30vw; */
    background-color: #f4f4f4;
    margin-top: 100px;
}

.reset-password-form {
    background: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.reset-password-form h2 {
    margin-bottom: 20px;
}

.reset-password-form input {
    display: block;
    width: 300px;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.reset-password-form button {
    padding: 10px 20px;
    border: none;
    background: #007bff;
    color: white;
    border-radius: 5px;
    cursor: pointer;
}

.reset-password-form p {
    margin-top: 10px;
    color: red;
}