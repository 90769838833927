.fund-with-card-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f5f5f5;
}

.fund-card {
    padding: 20px;
    max-width: 400px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    border-radius: 10px;
}

.amount-input {
    margin-bottom: 20px;
}

.payment-button {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}